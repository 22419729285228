@mixin mobile-only {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin tablet-only {
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 1025px) {
    @content;
  }
}

@mixin desktop-plus {
  @media only screen and (min-width: 1600px) {
    @content;
  }
}
