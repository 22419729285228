@use 'sass:math';

$grid-columns: 12;
$grid-gutter: 24px;

$grid-mobile-padding: 16px;
$grid-tablet-padding: 5%;
$grid-desktop-padding: 8%;

// use these grid mixins

@mixin grid {
  @extend %grid;
}

@mixin grid-like {
  // for a full section without the columns
  @extend %grid-like;
}

@mixin grid-mobile($cols) {
  @extend %grid-item;
  @extend %grid-mobile-#{$cols};
}

@mixin grid-tablet($cols) {
  @extend %grid-item;
  @extend %grid-tablet-#{$cols};
}

@mixin grid-desktop($cols) {
  @extend %grid-item;
  @extend %grid-desktop-#{$cols};
}

// configuration and defaults for grid
// these shouldn't be used directly, instead use the mixins above

%grid {
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap;
  padding-left: $grid-mobile-padding;
  padding-right: $grid-mobile-padding;
}

%grid-like {
  box-sizing: border-box;
  padding-left: $grid-mobile-padding;
  padding-right: $grid-mobile-padding;
}

@include tablet {
  %grid {
    padding-left: $grid-tablet-padding;
    padding-right: $grid-tablet-padding;
  }

  %grid-like {
    padding-left: calc(#{$grid-tablet-padding} + #{$grid-gutter});
    padding-right: calc(#{$grid-tablet-padding} + #{$grid-gutter});
  }
}

@include desktop {
  %grid {
    padding-left: $grid-desktop-padding;
    padding-right: $grid-desktop-padding;
  }

  %grid-like {
    padding-left: calc(#{$grid-desktop-padding} + #{$grid-gutter});
    padding-right: calc(#{$grid-desktop-padding} + #{$grid-gutter});
  }
}

@include desktop-plus {
  %grid {
    padding-left: calc(50% - 720px);
    padding-right: calc(50% - 720px);
  }

  %grid-like {
    padding-left: calc(calc(50% - 720px) + #{$grid-gutter});
    padding-right: calc(calc(50% - 720px) + #{$grid-gutter});
  }
}

%grid-item {
  box-sizing: border-box;
  width: 100%;
}

// build grid item classes for each size

@mixin build-grid-items-for($size) {
  @for $i from 1 through ($grid-columns) {
    $_width: percentage(math.div($i, $grid-columns));

    %grid-#{$size}-#{$i} {
      margin: 0 $grid-gutter;
      width: calc(#{$_width} - #{$grid-gutter * 2});
    }
  }
}

@include build-grid-items-for("mobile");
@include tablet {
  @include build-grid-items-for("tablet");
}
@include desktop {
  @include build-grid-items-for("desktop");
}
