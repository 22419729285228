.cart__button {
  background: color("white");
  border: 2px solid color("keppel");
  border-radius: 40px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  color: color("keppel");
  height: 48px;
  line-height: 44px;
  padding: 0 24px;
  -webkit-tap-highlight-color: transparent;
  text-transform: none;
  transition: 0.2s;

  &:hover {
    color: color("space");
  }

  i {
    position: relative;
    top: 1px;
  }
}

@include desktop {
  .cart__button {
    border: 0;
    border-radius: 4px;
    box-shadow: none;
    height: 40px;
    line-height: 40px;
    padding: 0 12px;
  }
}

.cart__count {
  font-size: 14px;
  font-weight: 600;
  margin-left: 4px;
}
