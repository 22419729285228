.signin__menu {
  background: color("white");
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  opacity: 0;
  padding: 8px;
  position: absolute;
  right: 0;
  transition: 0.2s;
  top: 100%;
  visibility: hidden;
  width: 320px;
  z-index: 100;

  &::before {
    border: 6px solid transparent;
    border-bottom-color: color("white");
    content: "";
    position: absolute;
    right: 15px;
    top: -12px;
  }

  &.is-visible {
    opacity: 1;
    transform: translateY(16px);
    visibility: visible;
  }

  p {
    color: color("default", "text");
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    padding-top: 4px;
  }
}

.signin__item {
  border-radius: 4px;
  color: color("space");
  font-weight: 500;
  overflow: hidden;
  padding: 16px;
  padding-right: 48px;
  position: relative;
  text-transform: none;
  transition: 0.2s;

  &:first-child {
    margin: 0;
  }

  &:hover {
    background: darken(#f8fafc, 1%);
    color: color("space");

    i {
      color: color("keppel");
      transform: translateX(4px);
    }
  }

  i {
    color: lighten(color("default", "text"), 25%);
    font-size: 20px;
    line-height: 1;
    position: absolute;
    right: 20px;
    top: calc(50% - 10px);
    transition: 0.2s;
  }
}

.signin__heading {
  color: color("space");
}
