$footer-link-color: change-color(color("space"), $lightness: 80%);
$footer-link-color-hover: color("space", "text");

.main__footer {
  background: color("space");
  display: block;

  a {
    color: $footer-link-color;
  }

  a:hover {
    color: $footer-link-color-hover;
  }

  .main__logo {
    overflow: hidden;

    a {
      margin-top: -6px;
    }
  }
}

@include mobile-only {
  .main__footer .main__logo {
    float: left;
    margin-top: 1px;
  }
}

.footer__top {
  @include grid;
  padding-bottom: 24px;
  padding-top: 24px;
}

@include tablet {
  .footer__top {
    padding-bottom: 40px;
    padding-top: 80px;
  }
}

.footer__brand {
  @include grid-tablet(4);
}

.footer__column {
  @include grid-tablet(2);
}

@include mobile-only {
  .footer__column {
    display: none;
  }
}

.footer__heading {
  color: color("space", "text");
  font-weight: 500;
  padding-bottom: 4px;
}

.footer__link {
  display: block;
  font-size: 14px;
  margin-top: 8px;

  &.indented::before {
    content: "\2013";
    margin-right: 8px;
    opacity: 0.6;
  }
}

.footer__bottom {
  @include grid-like;
  overflow: hidden;
  padding-bottom: 24px;
  padding-top: 24px;
  position: relative;

  &::before {
    @include grid-like;
    background: color("space", "light");
    border-radius: 4px;
    content: "";
    display: block;
    height: 2px;
    left: 0;
    position: relative;
    top: -24px;
    width: 100%;
  }

  a {
    margin-left: 16px;
  }
}

@include mobile-only {
  .footer__bottom {
    text-align: center;
  }
}

@include tablet {
  .footer__bottom {
    padding-bottom: 40px;
    padding-top: 40px;
  }
}

.footer__social {
  overflow: hidden;

  a {
    align-items: center;
    background: $footer-link-color;
    border-radius: 50%;
    color: color("space");
    display: flex;
    float: left;
    height: 32px;
    justify-content: center;
    margin-right: 8px;
    transition: 0.2s;
    width: 32px;

    &:hover {
      background: $footer-link-color-hover;
      color: color("space");
    }
  }

  i {
    margin-top: 1px;
  }
}

@include mobile-only {
  .footer__social {
    float: right;
  }
}

@include tablet {
  .footer__social {
    padding-top: 8px;
  }
}

.footer__copyright {
  color: $footer-link-color;
  font-size: 14px;
}

.footer__legal {
  font-size: 14px;
}

@include mobile-only {
  .footer__copyright {
    padding-top: 8px;
  }
}

@include tablet {
  .footer__copyright {
    float: left;
  }

  .footer__legal {
    float: right;
  }
}
