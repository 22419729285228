$nav-link-color: color("keppel", "text-light");
$nav-link-hover-color: color("keppel", "text");
$nav-link-underline-color: color("mustard", "light");

.main__header {
  @include grid-like;
  background: color("keppel");
  display: flex !important;
  height: auto;
  padding-bottom: 12px;
  padding-top: 12px;
  transition: none;
  width: 100%;
}

.main__logo {
  a {
    display: block;
    float: left;
    height: 40px;
    margin-left: -8px;
    padding: 8px;
    padding-top: 6px;
  }

  img {
    border: 0;
    display: block;
    height: 24px;
    width: 87px;
  }
}

.main__nav {
  display: flex;
  flex: 1;
  font: inherit;
  margin: 0;
  padding: 0;
  padding-left: 8px;
  text-align: left;
}

@include desktop {
  .main__nav {
    display: flex;
  }
}

.nav__primary {
  flex: 1;
}

.nav__primary,
.nav__secondary {
  display: none;

  .has-more {
    padding-right: 36px;

    &::after {
      width: calc(100% - 48px);
    }

    i {
      font-size: 12px;
      margin-top: 0;
      opacity: 0.9;
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }
}

@include desktop {
  .nav__primary,
  .nav__secondary {
    display: block;
  }
}

.nav__secondary {
  position: relative;
}

.nav__link {
  color: $nav-link-color;
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  padding: 14px 12px 12px;
  position: relative;
  text-transform: none;
  transition: 0.2s;

  &::after {
    background: $nav-link-underline-color;
    border-radius: 2px;
    bottom: 4px;
    content: "";
    display: none;
    height: 2px;
    left: 12px;
    position: absolute;
    transform: scaleX(0);
    transition: transform 0.2s;
    width: calc(100% - 24px);
  }

  &.active,
  &:hover {
    color: $nav-link-hover-color;

    &::after {
      transform: scaleX(1);
    }
  }

  &.has-more {
    padding-right: 36px;

    &::after {
      width: calc(100% - 48px);
    }

    i {
      font-size: 12px;
      opacity: 0.9;
      position: absolute;
      right: 16px;
      top: 17px;
    }
  }
}

@include desktop {
  .nav__link {
    float: left;
    margin-left: 4px;

    &::after {
      display: block;
    }
  }
}

.nav__cart {
  bottom: 16px;
  left: 50%;
  padding: 0;
  position: fixed;
  transform: translateX(-50%);
  z-index: 1;
}

@include desktop {
  .nav__cart {
    padding-left: 12px;
    position: static;
    transform: none;
  }
}

.nav__separator {
  background: rgba(255, 255, 255, 0.25);
  height: 1px;
  margin: 16px auto;
  width: 80%;
}

// mobile

.mobile__menu-trigger {
  color: rgba(255, 255, 255, 0.6);
  display: block;
  font-size: 24px;
  padding: 8px;
  position: absolute;
  right: 8px;
  top: 11px;

  &:hover {
    color: #fff;
  }

  i {
    display: block;
  }
}

@include desktop {
  .mobile__menu,
  .mobile__menu-trigger {
    display: none;
  }
}

.mobile-menu-is-visible {
  overflow: hidden;

  .mobile__menu {
    height: 100%;
    opacity: 1;
    visibility: visible;
    width: 100%;
  }
}

.mobile__menu {
  backdrop-filter: blur(8px);
  background: rgba(color("space"), 0.9);
  height: 0;
  left: 0;
  opacity: 0;
  overflow: auto;
  padding: 40px;
  position: fixed;
  top: 0;
  transition: opacity 0.4s;
  visibility: hidden;
  width: 0;
  z-index: 100;

  .mobile__menu-trigger {
    color: $nav-link-hover-color;
    font-size: 28px;
    right: 10px;
    top: 10px;
  }
}

.mobile__nav {
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;

  .nav__link {
    color: #fff;
    display: block;
    padding: 16px;
    text-align: center;
  }
}

.cp__header {
  background: color("space");

  .nav__primary,
  .nav__secondary {
    a {
      color: rgba(color("space", "text"), 0.8);

      &:hover {
        color: color("space", "text");
      }

      &::after {
        background: color("keppel");
      }
    }
  }

  .cart__button {
    color: color("space");
  }
}
